.card {
    width: 100%;
    height: 100%;
}
  
model-viewer {
    width: 100%;
    height: 100%;
    --poster-color: #ffffff00;
}
model-viewer::part(default-ar-button) {
    top: 16px;
   right:16px;
   width:50px;
   height:50px;
}