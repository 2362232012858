.btn
{
    padding: 5px 25px;
    background-color: #d7d7d7;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-size: 15px;
    min-width: 100px;
}   
.pointer {
    cursor: pointer;
}