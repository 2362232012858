.ThreeSixyScreenshotGenertor{
    display: flex;
    align-items: center;
    justify-content: center;
    width:100%;
    height: 100%;
    flex-direction: column;
}
#Options{
    position: fixed;
    align-items: center;
    justify-content: center;
    text-align: center;
}
#Options div{
    margin: 2.5vh;
    display: block;
    padding: auto;
    align-items: center;
    justify-content: center;
    text-align: center;
}