.carouselContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.carouselContainer>img {
  max-height: 500px;
  max-width: 100%;
}

#btnPrev,
#btnNext {
  background-color: white;
  text-align: center;
  cursor: pointer;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

#btnPrev {
  position: absolute;
  /* left: 1rem; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 15px;
  cursor: pointer;
  border: 0px;
  left: 3%;
  background-color: transparent;
}

#btnNext {
  position: absolute;
  right: 3%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 15px;
  cursor: pointer;
  border: 0px;
  background-color: transparent;
}

#btnNext>img,
#btnPrev>img {
  width: 32px;
  height: 32px;
}

/* Media Query for Mobile View */
@media (max-width: 768px) {

  #btnNext>img,
  #btnPrev>img {
    width: 32px;
    height: 32px;
  }
}