.fileDropContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
}

.HomeButton{
    display: block;
    position: fixed;
    z-index: 101;
    top: 20px;
    right: 20px;
    padding: 10px 25px;
    background-color: #d7d7d7;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 0px;
    font-size: 15px;
    min-width: 75px;
}


.btnHelp {
    padding: 0px;
    display: block;
    position: fixed;
    top: 5%;
    left: 3%;
    background-color: transparent;
    text-align: center;
    border: 0px;
    cursor: pointer;
    z-index: 1;
}

.btnHelp>img {
    width: 32px;
    height: 32px;
}


.btnInput {
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    border: 0px;
}

.btnInput>img {
    height: 32px;
    width: 32px;
    margin: 6px;
}

.btnInput>p {
    opacity: 1;
}

input {
    opacity: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
}
.powered {
    background-color: transparent;
    background-image: linear-gradient(154deg, #0429e9 0%, #E21AFD 100%);
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    border: 0px;
    position: fixed;
    bottom: 0;
    right: 0;
    margin-right: 20px;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
}
.powered a {
    color: #fff;
    text-decoration-style: none;
}
/* Hide button for screens smaller than 600px */
@media (max-width: 600px) {
    .btnContainer {
        display: none;
    }
    .btnHelp {
        top: 20px;
        left: 25px;
    }   
    .btnHelp>img {
        width: 32px;
        height: 32px;
    }
    .HomeButton{
        position: absolute;
        bottom: 20px;
        left: 25px;
        top:auto;
        right:auto
    }
}