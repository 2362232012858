.Loader{
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
}