.main{
    width:100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
    flex-direction: column;
}
.previewMainContainer{
  width: 100%;
  height: 100%;
}

/* Media Query for Mobile View */
@media (max-width: 768px) {
  .main {
    width: 100%;
    height: 100%;
  }
  .btnHelp {
    top: 2%;
    left: 2%;
  }
}