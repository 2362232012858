.actions {
  display: flex;
  align-items: center;  
  justify-content: space-between;
}

.mouseInfoHeader{
  justify-content: center;
  align-content: center;
  color: black;
  text-align: center;
  padding: 10px;
  margin: 10px;
}

.mouseinfo{
  justify-content: center;
  align-content: start;
  background-color: white;
  display: flex;
  color: black;
  font-size: smaller;
  flex-direction: row;
  overflow-x: auto;
}

.actions{
  display: flex;
  flex-wrap: nowrap;
  background-color: white;
}

.imgtext{
  background-color: white;
  justify-content: center;
  align-content: flex-start;
  text-align: center;
  margin: 10px;
  width: 150px;
  flex: 0 0 auto;
  scroll-snap-align: start;
}

.imgtext img{
  background-color: white;
  height: 225px;
}

.mouseInfoCLose{
  text-align: right;
  justify-content: end;
  border-radius: 0px;
  border: 0px;
  background-color: white;
  cursor: pointer;
}

.mouseInfoCLose img{
  justify-content: end;
  height: 35px;
}
@media (max-width: 768px) {
  .mouseinfo{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow-x: hidden;
  }
  .imgtext{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    gap: 20px;
    padding: 10px;
  }
  .img{
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    width: 79px;
  }
  .text {
    display: flex;
    flex: 2;
    justify-content: center;
    align-items: center;
    font-size:medium;
  }
  .imgtext img{
    background-color: white;
    width: 100px;
    max-height: 100px;
  }
}